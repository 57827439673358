<template lang="pug">
.construct-cart
  .construct-cart__wrapper
    .construct-cart__products
      ConstructorProducts
    .construct-cart__color(:class="{'construct-cart__color-hidden': !productShow}")
      ConstructorCartColor
</template>

<script>
import ConstructorProducts from './ConstructorProducts/ConstructorProducts'
import ConstructorCartColor from './ConstructorCartColor/ConstructorCartColor'
import { mapGetters } from 'vuex'

export default {
  name: 'ConstructorCart',

  components: {
    ConstructorProducts,
    ConstructorCartColor
  },

  computed: {
    ...mapGetters([
      'productShow'
    ])
  }
}
</script>
