export default {
  async actionPut (context) {
    const response = await fetch('/api/v1/bundle/put/xls/')
    const complects = await response.json()

    context.commit('changeLoadCountPut', complects.length)
    context.commit('changePut', complects)
    context.commit('changeEndLoadPut', true)
  }
}
