<template lang="pug">
.construct-products(:class="{'construct-products-active': productShow}")
  .construct-products__wrapper
    .construct-products__top
      .construct-products__top-wrapper
        .construct-products__top-item
          .construct-products__top-title Товары в комплекте:
        .construct-products__top-item
          .construct-products__top-circle(@click="changeProductShow(!productShow)")
            svg.construct-products__top-icon
              use(xlink:href="#line-arrow")

    .construct-products__items
      .construct-products__item(v-for="(product, key) of totalProducts" :key="key")
        .construct-products__name
          .construct-products__article арт. {{ product[0].article }}
          a.construct-products__link(:href="product[0].url" target="_blank") {{ product[0].name }}
        .construct-products__price {{ product[0].price.value }} ₽ / {{ count }} шт.
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ConstructorProducts',

  computed: {
    ...mapGetters([
      'totalProducts',
      'count',
      'productShow'
    ])
  },

  methods: {
    ...mapMutations([
      'changeProductShow'
    ])
  }
}
</script>
