import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

const AppGeneration = defineAsyncComponent(() => import('../components/Generation/AppGeneration'))
const AppBundle = defineAsyncComponent(() => import('../components/Bundle/AppBundle'))
const AppProductUpdateListStock = defineAsyncComponent(() => import('../components/Product/Update/List/Stock/AppProductUpdateListStock.vue'))

const routes = [
  {
    path: '/generation-vue/',
    component: AppGeneration
  },
  {
    path: '/bundle-vue/',
    component: AppBundle
  },
  {
    path: '/product-update-list-stock-vue/',
    component: AppProductUpdateListStock
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
