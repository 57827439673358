export default {
  basePath: 'https://lightalight.ru',
  result: [],
  // тип генерации
  typeId: 0,
  // общие данные (массив)
  blockArr: {
    text: {
      buttonText: 'Обновить',
      text1: 'Обновление готово',
      text2: 'Всего товаров',
      text3: 'Ошибки'
    },
    product: {
      title: 'Товар',
      description: 'Вкладка «Товар»',
      blocks: {
        type13: {
          id: 13,
          title: 'Seo title',
          path: '/api/v1/generation/product/title/',
          text: 'Путь: Дополнительные поля (TV) -> Товар -> Шаблон title'
        },
        type14: {
          id: 14,
          title: 'Seo description',
          path: '/api/v1/generation/product/description/',
          text: 'Путь: Дополнительные поля (TV) -> Товар -> Шаблон description'
        }
      }
    },
    productSub: {
      title: 'Товар (поддомен)',
      description: 'Вкладка «Товар поддомен»',
      blocks: {
        type1: {
          id: 1,
          title: 'Seo title',
          path: '/api/v1/generation/product/title/sub/',
          text: 'Путь: Дополнительные поля (TV) -> Товар поддомен -> Шаблон title'
        },
        type2: {
          id: 2,
          title: 'Seo description',
          path: '/api/v1/generation/product/description/sub/',
          text: 'Путь: Дополнительные поля (TV) -> Товар поддомен -> Шаблон description'
        }
      }
    },
    section: {
      title: 'Категория',
      description: 'Вкладка «Категория»',
      blocks: {
        type11: {
          id: 11,
          title: 'Seo title (пагинация)',
          path: '/api/v1/generation/sections/title/page/',
          text: 'Путь: Дополнительные поля (TV) -> Категория -> Шаблон title (пагинация)'
        },
        type6: {
          id: 6,
          title: 'Seo description',
          path: '/api/v1/generation/sections/description/',
          text: 'Путь: Дополнительные поля (TV) -> Категория -> Шаблон description'
        },
        type7: {
          id: 7,
          title: 'Seo description (пагинация)',
          path: '/api/v1/generation/sections/description/page/',
          text: 'Путь: Дополнительные поля (TV) -> Категория -> Шаблон description (пагинация)'
        }
      }
    },
    sectionSub: {
      title: 'Категория (поддомены)',
      description: 'Вкладка «Категория поддомен»',
      blocks: {
        type8: {
          id: 8,
          title: 'Seo title',
          path: '/api/v1/generation/sections/title/sub/',
          text: 'Путь: Дополнительные поля (TV) -> Категория поддомен -> Шаблон title'
        },
        type12: {
          id: 12,
          title: 'Seo title (пагинация)',
          path: '/api/v1/generation/sections/title/sub/page/',
          text: 'Путь: Дополнительные поля (TV) -> Категория поддомен -> Шаблон title (пагинация)'
        },
        type9: {
          id: 9,
          title: 'Seo description',
          path: '/api/v1/generation/sections/description/sub/',
          text: 'Путь: Дополнительные поля (TV) -> Категория поддомен -> Шаблон description'
        },
        type10: {
          id: 10,
          title: 'Seo description (пагинация)',
          path: '/api/v1/generation/sections/description/sub/page/',
          text: 'Путь: Дополнительные поля (TV) -> Категория поддомен -> Шаблон description (пагинация)'
        }
      }
    },
    generationProduct: {
      title: 'Генерация текста, товар',
      description: 'Вкладка «Генерация текста товар»',
      blocks: {
        type3: {
          id: 3,
          title: 'Генерировать текст (первого типа)',
          path: '/api/v1/generation/product/content/1/',
          text: 'Путь: Дополнительные поля (TV) -> Генерация текста товар -> Шаблон (тип 1) (в поле "Категория товаров, тип 1" выбираем категории, для каких товаров будет произведена генерация)'
        },
        type4: {
          id: 4,
          title: 'Генерировать текст (второго типа)',
          path: '/api/v1/generation/product/content/2/',
          text: 'Путь: Дополнительные поля (TV) -> Генерация текста товар -> Шаблон (тип 2) (в поле "Категория товаров, тип 2" выбираем категории, для каких товаров будет произведена генерация)'
        },
        type5: {
          id: 5,
          title: 'Генерировать текст (категории)',
          // path: '/api/v1/generation/product/sections/',
          path: '/api/v1/generation/product/section/text/',
          text: 'Путь: Дополнительные поля (TV) -> Генерация текста товар -> Шаблон (список категорий товара), проходим по всем категориям товара и используем данный шаблон'
        }
      }
    }
  }
}
