<template lang="pug">
.construct-form
  .construct-form__wrapper
    .construct-form__fields
      .construct-form__field
        .input-text
          input.input-text__input(type="tel" placeholder="Ваше имя *")
      .construct-form__field
        .input-text
          input.input-text__input(type="tel" placeholder="Телефон *")
    .construct-form__button
      .button
        .button__text Отправить
        svg.button__icon.button__icon-right
          use(xlink:href="#option-button-arrow")
</template>

<script>
export default {
  name: 'ConstructorForm'
}
</script>
