export default {
  getDomen: s => s.domen,
  pathImg: (s, g) => g.getDomen + s.pathImg,

  postCount: s => s.fields.post.count,
  postActive: s => s.fields.post.active,

  typeList: s => s.fields.type,
  typeActiveKey: (s, g) => {
    let result = 0

    s.fields.type.forEach((item, key) => {
      if (parseInt(item.active['active' + g.postActive]) === 1) {
        result = key
      }
    })
    return result
  },
  typeActive: (s, g) => g.typeList[g.typeActiveKey],
  typeActiveId: (s, g) => currentPost => {
    const type = g.typeList.filter(item => {
      return item.active['active' + currentPost] === 1
    })

    return type[0].id
  },
  typeTvName: s => typeId => {
    if (parseInt(typeId) === 1) {
      return 'Product_slot_C1'
    }

    if (parseInt(typeId) === 2) {
      return 'Product_slot_C2'
    }

    if (parseInt(typeId) === 3) {
      return 'Product_slot_SR'
    }

    if (parseInt(typeId) === 4) {
      return 'Product_slot_SR'
    }

    if (parseInt(typeId) === 5) {
      return 'Product_slot_SR'
    }
  },

  colorList: s => s.fields.color,
  colorActiveKey: s => {
    let result = 0

    s.fields.color.forEach((item, key) => {
      if (parseInt(item.active) === 1) {
        result = key
      }
    })

    return result
  },
  colorActive: (s, g) => g.colorList[g.colorActiveKey],

  saleText: s => '-' + s.sale.number + '% ' + s.sale.month,
  saleTextValue: s => '-' + s.sale.number + '%',

  propsListAll: s => s.props,
  propsList: (s, g) => s.props.filter(item => {
    return parseInt(item.post) === parseInt(g.typeActive.id)
  }),
  isPropsTypePosition: (s, g) => (typeCurrent, postCurrent, position) => {
    return g.propsListAll.reduce((total, item) => {
      return (parseInt(item.post) === parseInt(typeCurrent) && (parseInt(item.polovinka) === 1) && (parseInt(item.active['active' + postCurrent][position]) === 1))
    }, false)
  },
  propsActive: (s, g) => (post, type, position) => {
    const result = []
    let isProduct = false

    g.propsListAll.forEach(item => {
      if ((parseInt(item.active['active' + post][position]) === 1) && (parseInt(item.post) === parseInt(type))) {
        if ((position === 'left') || ((position === 'right') && (parseInt(item.polovinka) === 1))) {
          result.push({ value: item.value, tv: item.tv, typeField: 'string', type, post })
          isProduct = true
        }
      }
    })

    if (isProduct) {
      result.push({ value: 1, tv: g.typeTvName(type), typeField: 'number', type, post })

      // добавляем цвет
      if ((parseInt(type) === 3) || parseInt(type) === 4 || parseInt(type) === 5) {
        result.push({ value: g.colorActive.name, tv: 'color', typeField: 'string', type, post })
      }
    }

    return result
  },
  propsListActiveType: (s, g) => (postCurrent, position) => {
    return g.propsListAll.filter(item => {
      if (position === 'left') {
        return ((parseInt(item.active['active' + postCurrent][position]) === 1)) && (parseInt(item.post) === parseInt(g.typeActiveId(postCurrent)))
      }

      if (position === 'right') {
        return ((parseInt(item.active['active' + postCurrent][position]) === 1)) && (parseInt(item.post) === parseInt(g.typeActiveId(postCurrent))) && (parseInt(item.polovinka) === 1)
      }
    })
  },

  productsList: s => s.products.list,
  productRamka: (s, g) => typesRamka => {
    return g.productsList.filter(item => {
      return (
        item.color === g.colorActive.name &&
        parseInt(item.props.product_count_post) === g.postCount &&
        ((item.props.product_konstruktor === typesRamka) || (item.props.product_konstruktor === typesRamka.split('').reverse().join('')))
      )
    })
  },

  totalProducts: (s, g, m) => {
    const propsResult = []
    const productsResult = []
    let typesRamka = ''
    const error = {
      status: false,
      text: ''
    }

    // формируем все опции для фильтра товаров
    for (let i = 1; i <= g.postCount; i++) {
      let ramka = g.typeActiveId(i)
      propsResult.push(g.propsActive(i, ramka, 'left'))
      propsResult.push(g.propsActive(i, ramka, 'right'))

      if (ramka === 3) {
        ramka = 0
      }
      if (ramka === 4) {
        ramka = 0
      }
      if (ramka === 5) {
        ramka = 0
      }
      typesRamka = typesRamka + '&' + ramka
    }

    // добавляем рамку
    const ramka = g.productRamka(typesRamka.slice(1))
    if (ramka.length > 0) {
      productsResult.push(ramka)
    } else {
      error.status = true
      error.text = 'Не найдена рамка'
    }

    // фильтруем товар по опциям
    propsResult.forEach(props => {
      if (props.length > 0) {
        const productItem = g.productsList.filter(product => {
          // let result = (product.color === color)
          let result = true

          props.forEach(prop => {
            if (prop.typeField === 'string') {
              if (product.props[prop.tv] !== prop.value) {
                result = false
              }
            } else {
              if (Math.ceil(product.props[prop.tv]) !== prop.value) {
                result = false
              }
            }
          })

          return result
        })

        if (productItem.length > 0) {
          productsResult.push(productItem)
        } else {
          error.status = true
          error.text = 'Не найден товар'
        }
      }

      s.error.notProduct = error.status
      s.error.notProductText = error.text
    })
    return productsResult
  },
  totalPrice: (s, g) => {
    return g.totalProducts.reduce((sum, item) => {
      return sum + (parseInt(item[0].price.value * g.count))
    }, 0)
  },
  totalPriceOld: (s, g) => {
    return g.totalProducts.reduce((sum, item) => {
      return sum + (parseInt(item[0].price.old * g.count))
    }, 0)
  },

  errorStatus: s => s.error.notProductText,

  count: s => s.count,

  complectCount: s => s.total.count,

  productShow: s => s.total.productShow
}
