/* global miniShop2 */

export default {
  changeType (context, payload) {
    context.commit('changeType', { keyTo: context.getters.typeActiveKey, keyDo: payload, post: context.getters.postActive })

    // Обнуляем кол-во добавленных комплектов
    context.commit('changeComplectCount', 0)

    // Изменяем кол-во конструктора на 1
    context.commit('changeCount', 1)
  },

  changeColor (context, payload) {
    context.commit('changeColor', { keyTo: context.getters.colorActiveKey, keyDo: payload })

    // Обнуляем кол-во добавленных комплектов
    context.commit('changeComplectCount', 0)

    // Изменяем кол-во конструктора на 1
    context.commit('changeCount', 1)
  },

  changeProps (context, payload) {
    const postActive = context.getters.postActive
    const props = context.getters.propsListAll
    const result = []
    let activePropLeft = { key: -1 }
    let activePropRight = { key: -1 }
    let propRight = { key: -1 }

    props.forEach((item, key) => {
      if ((parseInt(item.post) === parseInt(payload.post)) && (item.tv === payload.tv)) {
        if (parseInt(item.active['active' + postActive].right) === 1) {
          if (parseInt(item.polovinka) === 1) {
            activePropRight = item
            activePropRight.key = key
          } else {
            propRight = item
            propRight.key = key
          }
        }

        if (parseInt(item.active['active' + postActive].left) === 1) {
          if (parseInt(item.polovinka) === 0) {
            activePropLeft = item
            activePropLeft.key = key
          }
        }

        if ((parseInt(item.active['active' + postActive][payload.position]) === 1)) {
          result.push({
            key,
            value: 0,
            post: context.getters.postActive,
            position: payload.position
          })
        }

        if ((parseInt(item.id) === parseInt(payload.id))) {
          result.push({
            key,
            value: 1,
            post: context.getters.postActive,
            position: payload.position
          })
        }
      }
    })

    context.commit('changeProp', result)

    // Обнуляем кол-во добавленных комплектов
    context.commit('changeComplectCount', 0)

    // Изменяем кол-во конструктора на 1
    context.commit('changeCount', 1)
  },

  changePost (context, payload) {
    if (parseInt(payload) > 0 && (parseInt(payload) <= 5)) {
      context.commit('changePost', payload)

      // Обнуляем кол-во добавленных комплектов
      context.commit('changeComplectCount', 0)

      // Изменяем кол-во конструктора на 1
      context.commit('changeCount', 1)
    }
  },

  actionAddProducts (context, payload) {
    const products = context.getters.totalProducts.map(item => {
      return {
        id: item[0].id,
        count: context.getters.count
      }
    })

    if (process.env.NODE_ENV === 'development') {
      console.log('local')
      context.state.total.count += context.getters.count
    } else {
      $.ajax({
        type: 'POST',
        url: context.getters.getDomen + '/assets/template/lightalight/src/ajax/Constructor/ConstructorAddProducts.php',
        data: {
          constructor_ms2_action: 'constructor/add/products',
          products: JSON.stringify(products),
          options: {}
        },
        success: function (data) {
          context.state.total.count += context.getters.count
          data = JSON.parse(data)
          $('.ms2_total_count').text(data.total_count)
          miniShop2.Message.success('Корзина обновлена. Комплект добавлен в корзину.')
          // обновляем корзину самостоятельно
          $('.msMiniCart').addClass('full')
        }
      })
    }
  }
}
