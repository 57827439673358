import VueComponentsForm from './Form/VueComponentsForm'
import VueComponentsConstructor from './Constructor/VueComponentsConstructor'
import VueRouter from './Router/VueRouter'

export default class VueComponents {
  constructor () {
    /* eslint-disable no-new */
    new VueComponentsForm()
    new VueComponentsConstructor()
    new VueRouter()
  }
}
