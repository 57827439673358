export default class ProductCount {
  classItem = 'product-count'
  classCount = 'product-count__item-count'
  classMinus = 'product-count__item-minus'
  classPlus = 'product-count__item-plus'

  constructor () {
    const self = this

    $(document).on('click', `.${self.classPlus}`, function () {
      console.log('work plus')
      let count = parseInt($(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val())
      count += 1
      $(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val(count).trigger('change')
    })

    $(document).on('click', `.${self.classMinus}`, function () {
      let count = parseInt($(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val())

      if (count > 1) {
        count -= 1
      }

      $(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val(count).trigger('change')
    })
  }
}
