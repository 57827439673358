export default class PageDelivery {
  classButton = 'delivery-list__item'
  classButtonActive = 'delivery-list__item-active'

  constructor () {
    const self = this

    $(`.${self.classButton}`).click(function () {
      $(this).toggleClass(self.classButtonActive)
    })
  }
}
