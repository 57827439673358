<template lang="pug">
.construct-bottom
  .construct-bottom__wrapper
    .construct-bottom__frame-mobile
      ConstructorFrame
    .construct-bottom__type
      ConstructorType
    .construct-bottom__frames
      .construct-bottom__frame
        ConstructorFrame
      .construct-bottom__props
        ConstructorProps
</template>

<script>
import ConstructorFrame from './component/ConstructorFrame/ConstructorFrame'
import ConstructorProps from './component/ConstructorProps/ConstructorProps'
import ConstructorType from '../ConstructorTop/component/ConstructorType/ConstructorType'

export default {
  name: 'ConstructorBottom',

  components: {
    ConstructorFrame,
    ConstructorProps,
    ConstructorType
  }
}
</script>
