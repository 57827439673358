<template lang="pug">
.construct
  .construct__wrapper
    .construct__item
      ConstructorTop
    .construct__item
      ConstructorBottom
    .construct__item
      ConstructorRight
</template>

<script>
import ConstructorTop from './component/ConstructorTop/ConstructorTop'
import ConstructorRight from './component/ConstructorRight/ConstructorRight'
import ConstructorBottom from './component/ConstructorBottom/ConstructorBottom'
import { mapMutations } from 'vuex'

export default {
  name: 'AppConstructor',

  components: {
    ConstructorTop,
    ConstructorRight,
    ConstructorBottom
  },

  methods: {
    ...mapMutations([
      'changeSale',
      'changeProps',
      'changeProducts'
    ])
  },

  created () {
    const init = window.ConstructorApp
    this.changeSale(init.sale)
    this.changeProps(init.props)
    this.changeProducts(init.products)
  }
}
</script>
