import { createStore } from 'vuex'
import update from './module/update/index'
import put from './module/put'
import file from './module/file'

export default createStore({
  modules: {
    update,
    put,
    file
  }
})
