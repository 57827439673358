export default class SdekDeliveryPrice {
  constructor () {
    if ($('.sdek-delivery-day').length || $('.sdek-delivery-day-text').length || $('.sdek-delivery-price').length) {
      const cityTo = parseInt(window.appLight.fields.sdek.city.toId)
      const cityDo = parseInt(window.appLight.fields.sdek.city.doId)

      if (cityTo !== cityDo) {
        // Если localhost, то ничего не делаем
        if (location.hostname !== 'localhost') {
          fetch(`/api/v1/delivery/price/${cityDo}/`)
            .then(response => response.json())
            .then(data => {
              $('.sdek-delivery-day').text(data.day)
              $('.sdek-delivery-day-text').text(data.dayText)
              $('.sdek-delivery-price').text(data.priceMod)
              console.log(data)
            })
        }
      }
    }
  }
}
