<template lang="pug">
.construct-cart-color
  .construct-cart-color__wrapper
    .construct__title Цвет блока:
    .construct-cart-color__items
      .construct-cart-color__item
        .construct-cart-color__block
          .construct-cart-color__color(:class="('construct-cart-color__color-' + colorActive.value)")
      .construct-cart-color__item
        .construct-cart-color__name {{ colorActive.name }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConstructorCartColor',

  computed: {
    ...mapGetters([
      'colorActive'
    ])
  }
}
</script>
