export default {
  changeSale (state, payload) {
    state.sale = payload
  },

  changeProps (state, payload) {
    state.props = payload
  },

  changeProp (state, payload) {
    state.props[payload[0].key].active['active' + payload[0].post][payload[0].position] = payload[0].value
    state.props[payload[1].key].active['active' + payload[1].post][payload[1].position] = payload[1].value
  },

  changeProducts (state, payload) {
    state.products = payload
  },

  changePost (state, payload) {
    state.fields.post.count = payload
    state.fields.post.active = payload
  },

  changePostActive (state, payload) {
    state.fields.post.active = payload
  },

  changeType (state, payload) {
    state.fields.type[payload.keyTo].active['active' + payload.post] = 0
    state.fields.type[payload.keyDo].active['active' + payload.post] = 1
  },

  changeColor (state, payload) {
    state.fields.color[payload.keyTo].active = 0
    state.fields.color[payload.keyDo].active = 1
  },

  changeErrorNotProduct (state, payload) {
    state.error.notProduct = payload
  },

  changeCount (state, payload) {
    if (payload > 0) {
      state.count = payload
    }
  },

  changeProductShow (state, payload) {
    state.total.productShow = payload
  },

  changeComplectCount (state, payload) {
    state.total.count = payload
  }
}
