<template lang="pug">
.form-popup
  .form-popup__wrapper
    .h2.h2-line
      .h2__text Оставить отзыв
    .form-popup__block(v-show="!send")
      .form-popup__fields
        .form-popup__field
          .input-text(:class="{'input-text-focus': focusedName, 'input-text-error': v$.name.$invalid && (name.length > 0)}")
            input.input-text__input.input-text__input-gray(
              type="text"
              placeholder="Ваше имя*"
              v-model="name"
              @focus="focusedName = true"
              @blur="focusedName = false"
            )
        .form-popup__field
          .input-text(:class="{'input-text-focus': focusedEmail, 'input-text-error': v$.email.$invalid && (email.length > 0)}")
            input.input-text__input.input-text__input-gray(
              type="email"
              placeholder="Ваш email*"
              v-model="email"
              @focus="focusedEmail = true"
              @blur="focusedEmail = false"
            )
        .form-popup__field
          .form-popup__textarea
            .input-textarea
              textarea.input-textarea__input.input-textarea__input-gray(
                placeholder="Ваш отзыв"
                v-model="message"
              )
      .form-popup__consent
        .consent
          .consent__wrapper
            input.consent__input(
              type="checkbox"
              id="form-popup-review-label"
              checked="checked"
              value="consent"
            )
            label.consent__label.consent__label-small(for="form-popup-review-label") Отправляя форму, я соглашаюсь с политикой обработки персональных данных
      .form-popup__button
        .form-popup__button-wrapper
          .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
            .button__text Перезвонить
            svg.button__icon.button__icon-right
              use(xlink:href="#option-button-arrow")
    .form-popup__success(v-show="send")
      p.p-center Ваш отзыв отправлен.
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'FromReviewPopup',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      name: '',
      email: '',
      message: '',
      focusedName: false,
      focusedEmail: false,
      send: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Оставить отзыв"'
          },

          fields: [
            {
              title: 'Имя',
              value: this.name
            },
            {
              title: 'E-mail',
              value: this.email
            },
            {
              title: 'Отзыв',
              value: this.message
            },
            {
              title: 'Roistat',
              value: window.appLight.fields.roistat
            }
          ],
          files: ''
        })

        this.name = this.phone = ''

        setTimeout(() => {
          this.send = !this.send
        }, 4000)
      }
    }
  },

  validations () {
    return {
      name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  }
}
</script>
