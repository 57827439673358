export default class HeaderPopup {
  classButton = 'menu-toggle'
  classButtonClose = 'header-popup__close'
  classHeader = 'header-popup'
  classHeaderActive = 'header-popup-active'
  classBodyOverflow = 'body-overflow'

  constructor () {
    const self = this

    $(`.${self.classButton}, .${self.classButtonClose}`).click(function () {
      $(`.${self.classHeader}`).toggleClass(self.classHeaderActive)
      $('body').toggleClass(self.classBodyOverflow)
    })
  }
}
