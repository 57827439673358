<template lang="pug">
.app
  router-view
</template>

<script>
export default {
  name: 'App'
}
</script>
