import { createApp } from 'vue'
import router from '../../../../../router/router'

import App from '../../../../../components/App'

import generation from '../../../../../store/generation/index'
import bundle from '../../../../../store/bundle'
import productUpdateListStock from '../../../../../store/product/update/list/stock/productUpdateListStock'

export default class VueRouter {
  constructor () {
    const appGeneration = createApp(App)
    appGeneration.use(router)
    appGeneration.use(generation)
    appGeneration.mount('#generation-vue')

    const appBundleUpdate = createApp(App)
    appBundleUpdate.use(router)
    appBundleUpdate.use(bundle)
    appBundleUpdate.mount('#bundle-vue')

    const AppProductUpdateListStock = createApp(App)
    AppProductUpdateListStock.use(router)
    AppProductUpdateListStock.use(productUpdateListStock)
    AppProductUpdateListStock.mount('#product-update-list-stock-vue')
  }
}
