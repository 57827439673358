<template lang="pug">
.construct-right
  .construct-right__wrapper
    .construct__title.construct__title-big.construct__title-none-480(v-show="!errorStatus") Итого:
    .construct-right__cart(v-show="!errorStatus")
      ConstructorCart
    .construct-right__total(v-show="!errorStatus")
      ConstructorTotal
    .construct-right__error(v-show="errorStatus")
      ConstructorError
</template>

<script>
import { mapGetters } from 'vuex'
import ConstructorCart from './component/ConstructorCart/ConstructorCart'
import ConstructorTotal from './component/ConstructorTotal/ConstructorTotal'
import ConstructorError from './component/ConstructorError/ConstructorError'

export default {
  name: 'ConstructorRight',

  components: {
    ConstructorCart,
    ConstructorTotal,
    ConstructorError
  },

  computed: {
    ...mapGetters([
      'errorStatus'
    ])
  }
}
</script>
