import { createApp } from 'vue'
import AppConstructor from '../../../../../components/Constructor/AppConstructor'
import constructor from '../../../../../store/constructor/index'

export default class VueComponentsConstructor {
  constructor () {
    if ($('#constructor-block').length > 0) {
      const app = createApp(AppConstructor)
      app.use(constructor)
      app.mount('#constructor-block')
    }
  }
}
