import { createStore } from 'vuex'
// import Vue from 'vue'

export default createStore({
  state: {
    basePath: location.protocol + '//' + location.hostname,
    send: false
  },
  mutations: {},
  actions: {
    async Send (context, payload) {
      if ((process.env.NODE_ENV === 'development')) {
        console.log(payload)
      } else {
        const fields = {
          title: payload.setting.title,
          subject: payload.setting.subject,
          fields: payload.fields
        }

        const formData = new FormData()
        formData.append('fields', JSON.stringify(fields))

        if (payload.files !== '') {
          for (let i = 0; i < payload.files.length; i++) {
            const file = payload.files[i]
            formData.append('files[' + i + ']', file)
          }
        }

        await fetch(context.getters.getBasePath + '/api/v1/send_form/', {
          method: 'POST',
          body: formData
        })
      }
    }
  },
  modules: {},

  getters: {
    getBasePath: s => s.basePath,
    isSend: s => s.send
  }
})
