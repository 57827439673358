<template lang="pug">
.construct-type
  .construct-type__wrapper
    .construct__title Тип механизма:
    .construct-type__items
        .construct-type__item(v-for="(item, key) of typeList" :key="key" @click="changeType(key)" :data-test="key")
          .construct-type-item(:class="{'construct-type-item-active': parseInt(item.active['active' + postActive]) === 1}")
            .construct-type-item__wrapper
              .construct-type-item__img
                img.construct-type-item__img-wrapper(:src="getDomen + imgArray[ key ].img" width="50" height="50")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConstructorType',

  data () {
    return {
      imgArray: []
    }
  },

  computed: {
    ...mapGetters([
      'getDomen',
      'pathImg',
      'typeList',
      'postActive'
    ])
  },

  methods: {
    ...mapActions([
      'changeType'
    ])
  },

  created () {
    this.imgArray = window.ConstructorApp.settings.img.type
  }
}
</script>
