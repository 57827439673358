export default {
  async actionGeneration (context, payload) {
    context.commit('changeResult', [])
    context.commit('changeTypeId', 0)

    const response = await fetch(context.getters.getBasePath + payload.path)
    const result = await response.json()

    console.log(result)
    context.commit('changeResult', result)
    context.commit('changeTypeId', payload.typeId)
  }
}
