<template lang="pug">
.construct-error
  .construct-error__wrapper
    .construct-error__close закрыть x
    .construct-error__text
      p К сожалению нам не удалось подобрать все комплектующие для этого выключателя.
      p Оставьте свой номер телефона и мы подскажем, как решить эту проблему.
    .construct-error__form
      ConstructorForm
</template>

<script>
import ConstructorForm from './component/ConstructorForm'

export default {
  name: 'ConstructorError',

  components: {
    ConstructorForm
  }
}
</script>
