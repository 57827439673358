export default class ProductTabContent {
  classMore = 'product-props__more'
  classTab = 'product-tab__tab'
  classTabActive = 'product-tab__tab-active'
  classContent = 'product-tab__content'
  classContentActive = 'product-tab__content-active'

  constructor () {
    const self = this

    $(`.${self.classTab}`).click(function () {
      const block = $(this).data('block')

      $(`.${self.classTab}`).removeClass(self.classTabActive)
      $(this).addClass(self.classTabActive)

      $(`.${self.classContent}`).removeClass(self.classContentActive)
      $(`.${self.classContent}[data-block="${block}"]`).addClass(self.classContentActive)
    })

    $(`.${self.classMore}`).click(function () {
      const block = 2

      const href = $(this).attr('href')
      $(`.${self.classTab}`).removeClass(self.classTabActive)
      $(`.${self.classTab}[data-block="${block}"]`).addClass(self.classTabActive)

      $(`.${self.classContent}`).removeClass(self.classContentActive)
      $(`.${self.classContent}[data-block="${block}"]`).addClass(self.classContentActive)

      $('html, body').animate({
        scrollTop: $(href).offset().top
      }, {
        duration: 370,
        easing: 'linear'
      })

      return false
    })
  }
}
