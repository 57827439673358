<template lang="pug">
.construct-props
    .construct-props__wrapper(:class="('construct-props__wrapper-' + postActive)")
      .construct-props__item(v-for="(prop, key) of propsList" :key="key" :class="{'construct-props__item-line': parseInt(prop.line) === 1}")
        .construct-prop
          .construct-prop__wrapper
            .construct-prop__item
              .construct-prop__check(
                :class="{'construct-prop__check-active': parseInt(prop.active['active' + postActive].left) === 1}"
                @click="changeProps({id: prop.id, post: prop.post, position: 'left', tv: prop.tv, polovinka: prop.polovinka})"
              )
            .construct-prop__item(v-if="parseInt(prop.polovinka) === 1")
              .construct-prop__check(
                :class="{'construct-prop__check-active': parseInt(prop.active['active' + postActive].right) === 1}"
                @click="changeProps({id: prop.id, post: prop.post, position: 'right', tv: prop.tv, polovinka: prop.polovinka})"
              )
            .construct-prop__item
              .construct-prop__name {{ prop.name }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConstructorProps',

  computed: {
    ...mapGetters([
      'postActive',
      'propsList'
    ])
  },

  methods: {
    ...mapActions([
      'changeProps'
    ])
  }
}
</script>
