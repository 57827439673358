<template lang="pug">
.form-calc
  .form-calc__wrapper(v-show="!send")
    .form-calc__item
      .form-calc__field
        .input-text(:class="{'input-text-focus': focusedName, 'input-text-error': v$.name.$invalid && (name.length > 0)}")
          input.input-text__input(
            type="text" placeholder="Имя *"
            v-model="name"
            @focus="focusedName = true"
            @blur="focusedName = false"
          )
      .form-calc__field
        .input-text(:class="{'input-text-focus': focusedFamily, 'input-text-error': v$.family.$invalid && (family.length > 0)}")
          input.input-text__input(
            type="text"
            placeholder="Фамилия *"
            v-model="family"
            @focus="focusedFamily = true"
            @blur="focusedFamily = false"
          )
      .form-calc__field
        .input-text(:class="{'input-text-focus': focusedPhone, 'input-text-error': v$.phone.$invalid && (phone.length > 0)}")
          input.input-text__input(
            type="tel"
            placeholder="Телефон *"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            v-model="phone"
            @focus="focusedPhone = true"
            @blur="focusedPhone = false"
          )
      .form-calc__field
        .input-text(:class="{'input-text-focus': focusedEmail, 'input-text-error': v$.email.$invalid && (email.length > 0)}")
          input.input-text__input(
            type="email"
            placeholder="Email *"
            v-model="email"
            @focus="focusedEmail = true"
            @blur="focusedEmail = false"
          )
    .form-calc__item
      .form-calc__colors
        .form-calc__color
          .input-color
            input.input-color__input(name="color" type="radio" id="color-white" value="Белый" v-model="color" )
            label.input-color__label.input-color__label-white(for="color-white")
              span.input-color__value.input-color__value-white белый
        .form-calc__color
          .input-color
            input.input-color__input(name="color" type="radio" id="color-black" value="Черный" v-model="color")
            label.input-color__label.input-color__label-black(for="color-black")
              span.input-color__value.input-color__value-black черный
        .form-calc__color
          .input-color
            input.input-color__input(name="color" type="radio" id="color-gold" value="Золотой" v-model="color")
            label.input-color__label.input-color__label-gold(for="color-gold")
              span.input-color__value.input-color__value-gold золотой
        .form-calc__color
          .input-color
            input.input-color__input(name="color" type="radio" id="color-gray" value="Серый" v-model="color")
            label.input-color__label.input-color__label-gray(for="color-gray")
              span.input-color__value.input-color__value-gray серый
    .form-calc__item
      .input-textarea
        textarea.input-textarea__input(
          placeholder="Дополнительная информация"
          v-model="message"
        )
    .form-calc__item
      .form-calc__field
        .input-file
          input.input-file__input(
            type="file"
            name="files"
            ref="files"
            id="files"
            multiple
            @change="onFileChange"
          )
          label.input-file__label(v-html="fileResult" for="files")
      .form-calc__field
        .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
          .button__text Отправить заявку
          svg.button__icon.button__icon-right
            use(xlink:href="#option-button-arrow")
  .form-calc__success(v-show="send")
    p.p-white.p-center «Спасибо за обращение. Мы работаем с понедельника по пятницу с 10 до 18. В субботу с 11 до 17.<br>Мы свяжемся с вами после обработки заявки»
</template>

<script>
/* global ym */

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'FormCalculation',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      name: '',
      family: '',
      phone: '',
      email: '',
      message: '',
      color: 'Белый',
      focusedName: false,
      focusedFamily: false,
      focusedEmail: false,
      focusedPhone: false,
      send: false,
      fileResult: 'Добавьте фото или схему',
      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (!this.v$.$invalid) {
        const curDate = new Date()
        const formDate = curDate.getDate() + '.' + curDate.getMonth() + '.' + curDate.getFullYear() + ' ' + curDate.getHours() + ':' + curDate.getMinutes()

        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Расчет стоимости" от ' + formDate
          },

          fields: [
            {
              title: 'Имя',
              value: this.name
            },
            {
              title: 'Фамилия',
              value: this.family
            },
            {
              title: 'E-mail',
              value: this.email
            },
            {
              title: 'Телефон',
              value: this.phone
            },
            {
              title: 'Цвет',
              value: this.color
            },
            {
              title: 'Сообщение',
              value: this.message
            },
            {
              title: 'ПРИ ОФОРМЛЕНИИ ЗАКАЗА СКОПИРОВАТЬ В clientID в мой склад',
              value: window.appLight.fields.roistat
            }
          ],
          files: this.files
        })

        this.name = this.family = this.email = this.phone = this.message = ''

        setTimeout(() => {
          this.send = !this.send

          // Отправить цель
          try {
            ym(21879676, 'reachGoal', 'ZAKAZATZVONOK')
            console.log('zend ZAKAZATZVONOK')
          } catch (err) {
            console.log(err)
          }
        }, 4000)
      }
    }
  },

  validations () {
    return {
      name: {
        minLength: minLength(3),
        required
      },
      family: {
        minLength: minLength(3),
        required
      },
      phone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      email: {
        required,
        email
      }
    }
  }
}
</script>
