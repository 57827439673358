<template lang="pug">
.construct-post
  .construct-post__wrapper
    .construct__title Кол-во постов:
    .construct-post__items
      .construct-post__item
        .construct-post__img
          img.construct-post__img-wrapper(:src="getDomen + img" width="61" height="50")
      .construct-post__item
        .construct-post__count
          .product-count
            .product-count__wrapper
              .product-count__item.product-count__item-minus(@click="changePost(postCount - 1)") -
              .product-count__item.product-count__item-count {{ postCount }}
              .product-count__item.product-count__item-plus(@click="changePost(postCount + 1)") +
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConstructorPost',

  data () {
    return {
      img: ''
    }
  },

  computed: {
    ...mapGetters([
      'getDomen',
      'pathImg',
      'postCount'
    ])
  },

  methods: {
    ...mapActions([
      'changePost'
    ])
  },

  created () {
    this.img = window.ConstructorApp.settings.img.post
  }
}
</script>
