import state from './module/state'
import mutations from './module/mutations'
import actions from './module/actions'
import getters from './module/getters'

import { createStore } from 'vuex'

export default createStore({
  namespaced: true,
  state,
  mutations,
  actions,
  getters
})
