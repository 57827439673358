export default class MenuPopupSub {
  classButton = 'menu-popup-item__button'
  classItem = 'menu-popup-item'
  classItemOpen = 'menu-popup-item-active'

  constructor () {
    const self = this

    $(`.${self.classButton}`).click(function () {
      $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
    })
  }
}
