export default class FilterSection {
  classButton = 'filter-button'
  classButtonCatalog = 'sections-filter-close'
  classButtonClose = 'filter-button-close'
  classClose = 'sections-filter__close'
  classBlock = 'sections-filter'
  classBlockActive = 'sections-filter-active'

  constructor () {
    const self = this

    $(`.${self.classButton}, .${self.classClose}, .${self.classButtonCatalog}`).click(function () {
      $(`.${self.classButton}`).toggleClass(self.classButtonClose)
      $(`.${self.classBlock}`).toggleClass(self.classBlockActive)
    })
  }
}
