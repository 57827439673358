export default {
  changePut (state, payload) {
    state.listPut = payload
  },

  changeLoadCountPut (state, payload) {
    state.loadCountPut = payload
  },

  changeEndLoadPut (state, payload) {
    state.endLoadPut = payload
  }
}
