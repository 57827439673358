import { createStore } from 'vuex'

export default createStore({
  state: {
    endLoadResult: false,
    loadButton: false,
    productList: []
  },

  mutations: {
    changeLoadButton (state, payload) {
      state.loadButton = payload
    },

    changeProductList (state, payload) {
      state.productList.push(payload)
    },

    changeEndLoadResult (state, payload) {
      state.endLoadResult = payload
    }
  },

  actions: {
    async actionList (context) {
      context.commit('changeLoadButton', true)

      let offset = 0
      let response
      let result

      do {
        response = await fetch(`https://lightalight.ru/api/v1/product/update/list/stock/${offset}/`)
        result = await response.json()

        if (response.ok) {
          offset += 1

          console.log(result)
          context.commit('changeProductList', result)
        }
      } while (!result.isEnd && response.ok)

      context.commit('changeEndLoadResult', true)
    }
  },

  modules: {},

  getters: {
    endLoadResult: s => s.endLoadResult,
    loadButton: s => s.loadButton,
    productList: s => s.productList
  }
})
