import { createApp } from 'vue'
import Maska from 'maska'
import form from '../../../../../store/form'
import FormCalculation from '../../../../../components/Form/Calculation/FormCalculation'
import FormPhonePopup from '../../../../../components/Form/FormPopup/FromPhonePopup/FromPhonePopup'
import FormReviewPopup from '../../../../../components/Form/FormPopup/FormReviewPopup/FormReviewPopup'

export default class VueComponentsForm {
  constructor () {
    if ($('#form-calc').length > 0) {
      createApp({
        components: {
          'form-calc': FormCalculation
        }
      })
        .use(Maska)
        .use(form)
        .mount('#form-calc')
    }

    if ($('#form-popup-phone-view').length > 0) {
      const app = createApp(FormPhonePopup)
      app.use(Maska).use(form).mount('#form-popup-phone-view')
    }

    if ($('#form-popup-review-view').length > 0) {
      const app = createApp(FormReviewPopup)
      app.use(Maska).use(form).mount('#form-popup-review-view')
    }
  }
}
