<template lang="pug">
.construct-frame
  .construct-frame__frames
    .construct-frame__frame(v-for="(item, key) of postCount" :key="key")
      .construct-frame-item(:class="{'construct-frame-item-active': item === postActive}" @click="changePostActive(item)")
        .construct-frame-item__number {{ item }}

  .construct-frame__border(:class="('construct-frame__border-' + postCount)" :style="{ backgroundImage: 'url(' + pathImg + borderPath + colorActive.value + '/' + postCount + '.jpg' + ')' }")
    .construct-frame__frames.construct-frame__frames-border(:class="('construct-frame__frames-border-' + postCount)")
      .construct-frame__frame(v-for="(item, key) of postCount" :key="key")
        .construct-frame-item(:class="{'construct-frame-item-active': item === postActive}" @click="changePostActive(item)")
          .construct-frame-item__imgs
            .construct-frame-item__img(
              v-if="(typeActiveId(key + 1) === 3) || (typeActiveId(key + 1) === 4) || (typeActiveId(key + 1) === 5)"
              :class="{'construct-frame-item__img-ramka': true}"
            )
              img.construct-frame-item__img-wrapper(
                :src="pathImg + borderPath + colorActive.value +'/r.png'"
                width="140"
                height="140"
              )
            .construct-frame-item__img(
              v-for="(prop, keyProp) of propsListActiveType(key + 1, 'left')"
              :key="keyProp"
              :class="{'construct-frame-item__img-center': parseInt(prop.polovinka) === 0, 'construct-frame-item__img-left': parseInt(prop.polovinka) === 1}"
            )
              img.construct-frame-item__img-wrapper(
                :src="getDomen + prop.img[ colorActive.value ].img"
                width="140"
                height="140"
              )
            .construct-frame-item__img(
              v-for="(prop, keyProp) of propsListActiveType(key + 1, 'right')"
              :key="keyProp"
              :class="{'construct-frame-item__img-right': true}"
            )
              img.construct-frame-item__img-wrapper(
                :src="getDomen + prop.img[ colorActive.value ].imgRight"
                width="140"
                height="140"
              )

  .construct-frame__frames
    .construct-frame__frame(v-for="(item, key) of postCount" :key="key")
      .construct-frame-item(:class="{'construct-frame-item-active': item === postActive}" @click="changePostActive(item)")
        .construct-frame-item__circle

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ConstructorFrame',

  data () {
    return {
      borderPath: 'constructor/color/',
      replaceImgPath: {
        black: {
          to: '/white/',
          do: '/black/'
        },
        white: {
          to: '/white/',
          do: '/white/'
        },
        gold: {
          to: '/white/',
          do: '/gold/'
        },
        gray: {
          to: '/white/',
          do: '/gray/'
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getDomen',
      'pathImg',
      'colorActive',
      'postCount',
      'postActive',
      'propsListActiveType',
      'typeActiveId'
    ])
  },

  methods: {
    ...mapMutations([
      'changePostActive'
    ])
  }
}
</script>
