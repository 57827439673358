export default class Ecommerce {
  classButtonAdd = 'button--add'
  classButtonAddActive = 'button--add-active'

  classButtonDelete = 'button--remove'
  classButtonDeleteActive = 'button--remove-active'

  constructor () {
    const self = this

    $(document).delegate(`.${self.classButtonAdd}`, 'click', function () {
      $(this).closest('body').find(`.${self.classButtonAdd}`).removeClass(self.classButtonAddActive)
      $(this).closest('body').find(`.${self.classButtonDelete}`).removeClass(self.classButtonDeleteActive)

      $(this).addClass(self.classButtonAddActive)
    })

    $(document).delegate(`.${self.classButtonDelete}`, 'click', function () {
      $(this).closest('body').find(`.${self.classButtonAdd}`).removeClass(self.classButtonAddActive)
      $(this).closest('body').find(`.${self.classButtonDelete}`).removeClass(self.classButtonDeleteActive)

      $(this).addClass(self.classButtonDeleteActive)
    })
  }
}
