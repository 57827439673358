import './assets/js/css/ImportCss'
import MenuPopupSub from './assets/js/Menu/MenuPopupSub'
import HeaderPopup from './assets/js/Popup/HeaderPopup'
import VueComponents from './assets/js/Vue/VueComponents/VueComponents'
import FilterSection from './assets/js/Filter/FilterSection'
import ProductTabContent from './assets/js/Product/ProductTabContent'
import ContactBlock from './assets/js/PageContact/ContactBlock'
import PageDelivery from './assets/js/PageDelivery/PageDelivery'
import SdekDeliveryPrice from './assets/js/SDEK/SdekDeliveryPrice'
import ProductCount from './assets/js/ProductCount/ProductCount'
import SwiperSlider from './assets/js/Swiper/Swiper'
import Ecommerce from './assets/js/Ecommerce/Ecommerce'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

// Подключаем fancybox
require('@fancyapps/fancybox')

/* eslint-disable no-new */
$(document).ready(function () {
  new SwiperSlider()
  new MenuPopupSub()
  new HeaderPopup()
  new VueComponents()
  new FilterSection()
  new ProductTabContent()
  new ContactBlock()
  new PageDelivery()
  new ProductCount()
  new SdekDeliveryPrice()
  new Ecommerce()
})
