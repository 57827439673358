export default class ContactBlock {
  classButton = 'contact-block'
  classButtonActive = 'contact-block-active'

  constructor () {
    const self = this

    $(`.${self.classButton}`).click(function () {
      $(this).toggleClass(self.classButtonActive)
    })
  }
}
