import Swiper, { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay])

export default class SwiperSlider {
  createReviewSlider () {
    return new Swiper('#swiper-index-review', {
      slidesPerView: 2,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: '.swiper-index-review-next',
        prevEl: '.swiper-index-review-prev'
      },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        },
        360: {
          slidesPerView: 1
        },
        375: {
          slidesPerView: 1
        },
        414: {
          slidesPerView: 1
        },
        480: {
          slidesPerView: 1
        },
        514: {
          slidesPerView: 1
        },
        640: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        991: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1240: {
          slidesPerView: 2
        }
      }
    })
  }

  createIndexProductsSlider (number) {
    return new Swiper('.swiper-index-products-' + number, {
      slidesPerView: 4,
      spaceBetween: 15,
      loop: false,
      navigation: {
        nextEl: '.swiper-index-products-' + number + '-next',
        prevEl: '.swiper-index-products-' + number + '-prev'
      },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        },
        360: {
          slidesPerView: 1
        },
        375: {
          slidesPerView: 1
        },
        414: {
          slidesPerView: 1
        },
        480: {
          slidesPerView: 2
        },
        514: {
          slidesPerView: 2
        },
        640: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 2
        },
        991: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 3
        },
        1240: {
          slidesPerView: 4
        }
      }
    })
  }

  constructor () {
    if (parseInt($('#swiper-index-review').length) > 0) {
      this.createReviewSlider()
    }

    if (parseInt($('.index-products').length) > 0) {
      this.createIndexProductsSlider(1)
      this.createIndexProductsSlider(2)
      this.createIndexProductsSlider(3)
      this.createIndexProductsSlider(4)
    }
  }
}
