<template lang="pug">
.construct-top
  .construct-top__wrapper
    .construct-top__item
      .construct-top__post
        ConstructorPost
    .construct-top__item
      .construct-top__type
        ConstructorType
    .construct-top__item
      .construct-top__color
        ConstructorColor
</template>

<script>
import ConstructorPost from './component/ConstructorPost/ConstructorPost'
import ConstructorType from './component/ConstructorType/ConstructorType'
import ConstructorColor from './component/ConstructorColor/ConstructorColor'

export default {
  name: 'ConstructorTop',

  components: {
    ConstructorPost,
    ConstructorType,
    ConstructorColor
  }
}
</script>
