export default {
  domen: 'https://lightalight.ru',
  pathImg: '/assets/template/lightalight/img/',
  fields: {
    post: {
      count: 1,
      active: 1
    },
    type: [
      {
        id: 1,
        name: 'type 1',
        active: {
          active1: 1,
          active2: 1,
          active3: 1,
          active4: 1,
          active5: 1
        }
      },
      {
        id: 2,
        name: 'type 2',
        active: {
          active1: 0,
          active2: 0,
          active3: 0,
          active4: 0,
          active5: 0
        }
      },
      {
        id: 3,
        name: 'type 3',
        active: {
          active1: 0,
          active2: 0,
          active3: 0,
          active4: 0,
          active5: 0
        }
      },
      {
        id: 4,
        name: 'type 4',
        active: {
          active1: 0,
          active2: 0,
          active3: 0,
          active4: 0,
          active5: 0
        }
      },
      {
        id: 5,
        name: 'type 5',
        active: {
          active1: 0,
          active2: 0,
          active3: 0,
          active4: 0,
          active5: 0
        }
      }
    ],
    color: [
      {
        name: 'Белый',
        value: 'white',
        active: 1
      },
      {
        name: 'Чёрный',
        value: 'black',
        active: 0
      },
      {
        name: 'Серый',
        value: 'gray',
        active: 0
      },
      {
        name: 'Золотой',
        value: 'gold',
        active: 0
      }
    ]
  },
  total: {
    products: [],
    count: 0,
    productShow: false
  },
  sale: {},
  props: [],
  products: {},
  error: {
    notProduct: false,
    notProductText: ''
  },
  count: 1
}
