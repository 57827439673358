<template lang="pug">
.construct-color
  .construct-color__wrapper
    .construct__title Цвет:
    .construct-color__items
      .construct-color__item(v-for="(item, key) of colorList" :key="key" @click="changeColor(key)")
        .construct-color-item(:class="{'construct-color-item-active': parseInt(item.active) === 1}")
          .construct-color-item__wrapper(:class="'construct-color-item__wrapper-' + item.value")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConstructorColor',

  computed: {
    ...mapGetters([
      'colorList'
    ])
  },

  methods: {
    ...mapActions([
      'changeColor'
    ])
  }
}
</script>
