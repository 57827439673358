<template lang="pug">
.form-popup(v-if="show")
  .form-popup__wrapper
    .h2.h2-line
      .h2__text Заказать звонок
    .form-popup__block(v-show="!send")
      .form-popup__fields
        .form-popup__field
          .input-text(:class="{'input-text-focus': focusedName}")
            input.input-text__input.input-text__input-gray(
              type="text"
              placeholder="Ваше имя"
              v-model="name"
              @focus="focusedName = true"
              @blur="focusedName = false"
            )
        .form-popup__field
          .input-text(:class="{'input-text-focus': focusedPhone, 'input-text-error': v$.phone.$invalid && (phone.length > 0)}")
            input.input-text__input.input-text__input-gray(
              type="tel "
              placeholder="Ваш телефон*"
              v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
              v-model="phone"
              @focus="focusedPhone = true"
              @blur="focusedPhone = false"
            )
      .form-popup__consent
        .consent
          .consent__wrapper
            input.consent__input(
              type="checkbox"
              id="form-popup-phone-label"
              checked="checked"
              value="consent"
            )
            label.consent__label.consent__label-small(for="form-popup-phone-label") Отправляя форму, я соглашаюсь с политикой обработки персональных данных
      .form-popup__button
        .form-popup__button-wrapper
          .button(:class="{'button-disable': v$.$invalid}" @click="getSend()")
            .button__text Перезвонить
            svg.button__icon.button__icon-right
              use(xlink:href="#option-button-arrow")
    .form-popup__success(v-show="send")
      p.p-center Ваше сообщение отправлено.
</template>

<script>
/* global ym */
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'FromPhonePopup',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      settings: {},
      name: '',
      phone: '',
      focusedName: false,
      focusedPhone: false,
      send: false,
      show: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        const curDate = new Date()
        const formDate = curDate.getDate() + '.' + curDate.getMonth() + '.' + curDate.getFullYear() + ' ' + curDate.getHours() + ':' + curDate.getMinutes()

        this.send = !this.send
        this.Send({
          setting: {
            title: 'Заявка с формы',
            subject: 'Заявка с формы "Заказать звонок" от ' + formDate
          },

          fields: [
            {
              title: 'Имя',
              value: this.name
            },
            {
              title: 'Телефон',
              value: this.phone
            },
            {
              title: 'ПРИ ОФОРМЛЕНИИ ЗАКАЗА СКОПИРОВАТЬ В clientID в мой склад',
              value: window.appLight.fields.roistat
            }
          ],
          files: ''
        })

        this.name = this.phone = ''

        setTimeout(() => {
          this.send = !this.send

          // Отправить цель
          try {
            ym(21879676, 'reachGoal', 'ZAKAZATZVONOK')
            console.log('zend ZAKAZATZVONOK')
          } catch (err) {
            console.log(err)
          }
        }, 4000)
      }
    }
  },

  validations () {
    return {
      phone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  },

  created () {
    if ($('[data-src="#form-popup-phone-view"]')) {
      this.show = true
    }
  }
}
</script>
