export default {

  async actionUpdate (context, payload) {
    let i = 0

    context.commit('changeLoadButton', true)

    do {
      const bundleID = payload.listBundle[i].trim()
      const priceList = payload.priceList

      if (bundleID) {
        // https://lightalight.ru
        const response = await fetch(`https://lightalight.ru/api/v1/bundle/list/${bundleID}/${priceList}/`)
        const complect = await response.json()

        if (response.ok) {
          console.log(complect)

          if (!complect.error) {
            context.commit('changeUpdate', complect)
          }
        }
      }

      context.commit('changeLoadCountUpdate', 1)
      i += 1
    } while (payload.listBundle.length > i)

    context.commit('changeLoadButton', false)
    context.commit('changeEndLoadUpdate', true)
  }
}
