export default {
  changeUpdate (state, payload) {
    state.listUpdate = state.listUpdate.concat(payload)
  },

  changeLoadCountUpdate (state, payload) {
    state.loadCountUpdate += payload
  },

  changeEndLoadUpdate (state, payload) {
    state.endLoadUpdate = payload
  },

  changeLoadButton (state, payload) {
    state.loadButton = payload
  }
}
