<template lang="pug">
.construct-total
  .construct-total__wrapper
    .construct__title.construct__title-none-480 Количество:
    .construct-total__price
      .product-price
        .product-price__wrapper
          .product-price__item
            .product-price__count
              .product-count
                .product-count__wrapper
                  .product-count__item.product-count__item-minus(@click="changeCount(count - 1)") -
                  .product-count__item.product-count__item-count {{ count }}
                  .product-count__item.product-count__item-plus(@click="changeCount(count + 1)") +
          .product-price__item
            .product-price__sale(:data-sale="saleText" v-show="totalPriceOld > totalPrice") {{ totalPriceOld }} ₽
            .product-price__price {{ totalPrice }}  ₽
    .construct-total__mobile
      .construct-total__mobile-wrapper
        .construct-total__mobile-item
          .construct-total__mobile-sale {{ totalPriceOld }} ₽
        .construct-total__mobile-item
          .construct-total__mobile-price {{ totalPrice }} ₽
        .construct-total__mobile-item
          .construct-total__mobile-saleText {{ saleText }}
    .construct-total__error(v-if="errorStatus")
      .construct-total-error
        .construct-total-error__wrapper
          .construct-total-error__title Товары не собраны
          .construct-total-error__text {{ errorText }}
    .construct-total__button
      .construct-total__button-wrapper
        .construct-total__button-item
          .product-count
            .product-count__wrapper
              .product-count__item.product-count__item-minus(@click="changeCount(count - 1)") -
              .product-count__item.product-count__item-count {{ count }}
              .product-count__item.product-count__item-plus(@click="changeCount(count + 1)") +
        .construct-total__button-item
          .button(@click="actionAddProducts()")
            svg.button__icon.button__icon-left.button__icon-cart
              use(xlink:href="#cart-small")
            .button__text В корзину
    .construct-total__count(v-if="complectCount > 0")
      .construct-total__count-wrapper Добавлено в корзину {{complectCount}} шт.
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'ConstructorTotal',

  computed: {
    ...mapGetters([
      'saleText',
      'saleTextValue',
      'totalPrice',
      'totalPriceOld',
      'errorStatus',
      'errorText',
      'count',
      'complectCount'
    ])
  },

  methods: {
    ...mapMutations([
      'changeCount'
    ]),

    ...mapActions([
      'actionAddProducts'
    ])
  }
}
</script>
